import { BackendGameSlugs } from "src/integrations/backend/BackendGameSlugs";
import { FrontendGameSlugs } from "../interfaces/FrontendGameSlugs";
import { BACKEND_GAME_SLUG } from "../envs";

export const gameSlugsMap: Record<BackendGameSlugs, FrontendGameSlugs> = {
  coinFlip: "coinFlip",
  luckyStrike: "luckyStrike",
  rocketCrash: "toTheMoon",
  goldRush: "miner",
  plinko: "plinko"
};

export const FRONTEND_GAME_SLUG: FrontendGameSlugs = gameSlugsMap[BACKEND_GAME_SLUG as BackendGameSlugs];
